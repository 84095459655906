import React, { useState, useEffect, useRef } from "react";
import { keyframes, styled } from "@stitches/react";
import { Box, Button, Flex, Text, Tooltip } from "@radix-ui/themes";
import { convertTimestampToDate, getFormattedUTCDate } from "../utils";
import colors from "./colors";
import errorIcon from "../icons/error.svg";
import api from "../api/api";
import { CiCircleInfo } from "react-icons/ci";

const Container = styled(Flex, {
  width: "100%",
  height: "100%",
  maxWidth: "650px",
  height: "350px",
  display: "flex",
  border: "1px solid #ccc",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "#f0f0f0",
  position: "relative",
  flex: "1 1 calc(50% - 10px)",

  variants: {
    active: {
      true: {
        border: "1px solid red",
      },
    },
  },
});

const ImageElement = styled("img", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  display: (props) => (props.isStreaming ? "block" : "none"),
});

const CameraName = styled(Box, {
  position: "absolute",
  bottom: "10px",
  left: "10px",
  color: "#fff",
  padding: "5px",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
});

const Restart = styled(Box, {
  position: "absolute",
  bottom: "10px",
  right: "10px",
  color: "#fff",
});

const InfoIconContainer = styled(Flex, {
  position: "absolute",
  top: "10px",
  left: "10px",
  cursor: "pointer",
  color: "#fff",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "50%",
  padding: "5px",
  zIndex: 50,
});

const InfoDropdown = styled(Flex, {
  position: "absolute",
  top: "40px",
  left: "10px",
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "10px",
  zIndex: 100,
  width: "250px",
});

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const Loader = styled(Flex, {
  border: `4px solid ${colors.deepGray}`,
  borderTop: `4px solid ${colors.base}`,
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: `${spin} 1s linear infinite`,
});

//centered error message
const ErrorBox = styled(Flex, {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
});

const StartButton = styled(Button, {
  marginTop: "10px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  padding: "8px 12px",
  borderRadius: "4px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#0056b3",
  },
});

const RtspLive = ({ camera, onStreamSuccess, active, onActive }) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [hasError, setHasError] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [startingStream, setStartingStream] = React.useState(false);
  const [showInfoDropdown, setShowInfoDropdown] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const checkCameraStatus = async () => {
      setCheckingStatus(true);
      try {
        const response = await api.get(`/camera/check-camera/${camera.id}`);
        if (response.status === 200) {
          setHasError(!response.data.isAlive);
        } else {
          setHasError(true);
        }
        setCheckingStatus(false);
      } catch (error) {
        setCheckingStatus(false);
        setHasError(true);
      }
    };

    checkCameraStatus();
  }, []);

  useEffect(() => {
    // Check every 5 seconds if the image is still updating
    const checkInterval = setInterval(() => {
      const currentTime = Date.now();
      const timePassed = (currentTime - lastUpdated) / 1000;

      if (timePassed > 5 && imgRef.current) {
        // 5-second threshold
        // If the image has not updated in the last 5 seconds, refetch
        imgRef.current.src = `${
          camera.playback
        }?timestamp=${new Date().getTime()}`;
        setLastUpdated(Date.now());
      }
    }, 5000);

    return () => clearInterval(checkInterval); // Cleanup the interval on unmount
  }, [lastUpdated, camera.playback]);

  const handleImageLoad = () => {
    setLastUpdated(Date.now());
  };

  const startSteam = async () => {
    setStartingStream(true);
    try {
      const response = await api.get(`/camera/${camera.id}/start`);
      onStreamSuccess(camera.id, response.data);
      setStartingStream(false);
    } catch (error) {
      console.error("Error fetching Camera:", error);
      setStartingStream(false);
    }
  };

  if (startingStream || checkingStatus) {
    return (
      <Container justify="center" align="center" direction="column">
        <Loader></Loader>
      </Container>
    );
  }

  if (hasError) {
    return (
      <Container
        active={active}
        justify="center"
        align="center"
        direction="column"
      >
        <ErrorBox align="center" justify="center" direction="column">
          <img src={errorIcon} alt="Error tag" />
          <Text mt="20px" size="5" weight="bold" color="violet">
            Oops! something went wrong....
          </Text>
          <Text mt="10px" size="3" color="violet">
            Camera might be down or settings might be incorrect
          </Text>
        </ErrorBox>
      </Container>
    );
  }

  return (
    <Container
      active={active}
      onClick={() => onActive(camera)}
      justify="center"
      align="center"
      direction="column"
    >
      {camera.playback && (
        <Box>
          <InfoIconContainer
            onClick={(e) => {
              e.stopPropagation();
              setShowInfoDropdown(!showInfoDropdown);
            }}
          >
            <CiCircleInfo />
          </InfoIconContainer>
          {showInfoDropdown && (
            <InfoDropdown direction="column">
              <Flex>
                <Text size="2" color="violet">
                  Name:
                </Text>
                <Text size="2" color="violet" ml="auto">
                  {camera.name}
                </Text>
              </Flex>
              <Flex mt="5px">
                <Text size="2" color="violet">
                  Width:
                </Text>
                <Text size="2" color="violet" ml="auto">
                  {camera.width}
                </Text>
              </Flex>
              <Flex mt="5px">
                <Text size="2" color="violet">
                  Height:
                </Text>
                <Text size="2" color="violet" ml="auto">
                  {camera.height}
                </Text>
              </Flex>
              <Flex mt="5px">
                <Text size="2" color="violet">
                  Updated:
                </Text>
                <Text size="2" color="violet" ml="auto">
                  {convertTimestampToDate(lastUpdated)}
                </Text>
              </Flex>
            </InfoDropdown>
          )}
          <ImageElement
            security="restricted"
            onLoad={handleImageLoad}
            ref={imgRef} // Attach the ref here
            src={camera.playback}
            isStreaming={isStreaming}
          />
          <CameraName>{camera.name}</CameraName>
          <Restart>
            <Button size="1" onClick={startSteam}>
              Restart Feed
            </Button>
          </Restart>
        </Box>
      )}
      {!camera.playback && (
        <Flex align="center" justify="center" direction="column">
          <Text size="4" color="violet" weight="bold">
            No Stream Available
          </Text>
          <StartButton onClick={startSteam} disabled={startingStream} mt="10px">
            Start stream
          </StartButton>
        </Flex>
      )}
    </Container>
  );
};

export default RtspLive;
