import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@stitches/react";

const Container = styled("div", {
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
});

const Iframe = styled("iframe", {
  width: "100%",
  height: "100vh",
  border: "none",
});

const EyalSafety = () => {
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    const fetchTokenAndSetIframe = async () => {
      debugger;
      const body = {
        site_ids: ["9d015a5e-03c7-4078-995f-ef8c6df33034"],
        company_id: process.env.REACT_APP_COMPANY_ID,
        first_name: process.env.REACT_APP_FIRST_NAME,
        last_name: process.env.REACT_APP_LAST_NAME,
        email: process.env.REACT_APP_EMAIL,
        username: process.env.REACT_APP_USERNAME,
        group_name: process.env.REACT_APP_GROUP_NAME,
      };
      const tokenEndpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
      const bearerToken = process.env.REACT_APP_BEARER_TOKEN;
      const iframeBaseUrl = process.env.REACT_APP_IFRAME_BASE_URL;

      try {
        const response = await axios.post(tokenEndpoint, body, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });

        if (response.data && response.data.token) {
          const token = response.data.token;
          setIframeSrc(`${iframeBaseUrl}${token}`);
        } else {
          console.error("Token not found in response:", response.data);
        }
      } catch (error) {
        console.error("Failed to fetch token:", error);
      }
    };

    fetchTokenAndSetIframe();
  }, []);

  return (
    <Container>
      {iframeSrc ? (
        <Iframe
          src={iframeSrc}
          style={{ width: "100vw", height: "100vh", border: "none" }}
          title="Dynamic Iframe"
        />
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
};

export default EyalSafety;
