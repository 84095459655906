import axios from "axios";
const isLocalhost = window.location.hostname === "localhost";

// Create an Axios instance
const api = axios.create({
  baseURL: isLocalhost
    ? "http://localhost:3001/platform/api"
    : "https://web.atom.construction/platform/api",
  withCredentials: true,
});

// Add a request interceptor to attach the access token to every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor to handle expired access tokens
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Request a new access token using the refresh token
        const { data } = await axios.post(
          "/refresh-token",
          {},
          { withCredentials: true }
        );

        localStorage.setItem("accessToken", data.accessToken);

        // Retry the original request with the new access token
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default api;
