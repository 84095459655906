import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { styled } from "@stitches/react";
import { IoChevronDown } from "react-icons/io5";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";

const TriggerButton = styled(DropdownMenu.Trigger, {
  backgroundColor: "#E6EBF9",
  padding: "10px 15px",
  borderRadius: "5px",
  fontSize: "16px",
  color: "#5a47d1",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 6px 0px rgba(183, 181, 188, 0.12)",
  minWidth: "150px",
});

const DropdownContent = styled(DropdownMenu.Content, {
  backgroundColor: "#f0f3ff",
  borderRadius: "8px",
  padding: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CalendarStyled = styled(Calendar, {
  "& .react-calendar__tile--active": {
    backgroundColor: "#7b61ff",
    color: "white",
  },
  "& .react-calendar__tile--now": {
    backgroundColor: "#e6e8ff",
    color: "#5a47d1",
  },
  "& .react-calendar__tile": {
    borderRadius: "8px",
    height: "40px",
    fontSize: "16px",
  },
  "& .react-calendar__navigation button": {
    color: "#5a47d1",
  },
  "& .react-calendar__navigation button:disabled": {
    color: "#c1c1c1",
  },
});

const MultiDateSelector = ({ selectedDates = [], onSelect }) => {
  const handleDateChange = (date) => {
    const dateString = date.toDateString();
    const newSelectedDates = selectedDates.some(
      (d) => d.toDateString() === dateString
    )
      ? selectedDates.filter((d) => d.toDateString() !== dateString)
      : [...selectedDates, date];

    if (onSelect) {
      onSelect(newSelectedDates); // Pass updated dates back to the parent
    }
  };

  const displayText = selectedDates.length
    ? `${selectedDates.length} selected`
    : "All";

  return (
    <DropdownMenu.Root>
      <TriggerButton>
        <span>{displayText}</span>
        <IoChevronDown />
      </TriggerButton>
      <DropdownContent>
        <CalendarStyled
          onClickDay={handleDateChange}
          tileClassName={({ date, view }) =>
            view === "month" &&
            selectedDates.some(
              (selectedDate) =>
                selectedDate.toDateString() === date.toDateString()
            )
              ? "react-calendar__tile--active"
              : null
          }
        />
      </DropdownContent>
    </DropdownMenu.Root>
  );
};

export default MultiDateSelector;
