import React, { createContext, useContext, useState, useEffect } from "react";
import { keyframes, styled } from "@stitches/react";
import { Flex } from "@radix-ui/themes";
import colors from "../components/colors";
import api from "../api/api";
import { useNavigate, useLocation } from "react-router-dom";

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const Loader = styled(Flex, {
  border: `4px solid ${colors.deepGray}`,
  borderTop: `4px solid ${colors.base}`,
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: `${spin} 1s linear infinite`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchProjects = async (id) => {
    setFetchingProjects(true);
    try {
      const response = await api.get(`user/${id}/projects`);
      setProjects(response.data);

      const params = new URLSearchParams(location.search);
      const projectID = params.get("projectID");

      // Check if projectID exists in the URL and matches a project in the list
      const initialProject = projectID
        ? response.data.find((p) => p.id === parseInt(projectID))
        : response.data[0];

      if (initialProject) {
        setSelectedProject(initialProject);
      }

      setFetchingProjects(false);
    } catch (err) {
      console.log("Error fetching projects");
      setFetchingProjects(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setFetchingUser(true);
      const response = await api.get("/user/current");
      if (response.data) {
        setUser(response.data);
        setFetchingUser(false);
        fetchProjects(response.data.id);
      }
    } catch (err) {
      setFetchingUser(false);
      console.log("Error fetching projects");
    }
  };

  const selectProject = (project) => {
    setSelectedProject(project);
    navigate(`?projectID=${project.id}`);
  };

  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("accessToken");
    navigate(`/login`);
  };

  if (fetchingProjects || fetchingUser) {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <AppContext.Provider
      value={{
        user,
        logoutUser,
        selectedProject,
        projects,
        fetchUserData,
        selectProject,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
