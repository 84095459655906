import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { styled } from "@stitches/react";
import { Checkbox } from "@radix-ui/react-checkbox";
import { IoMdCheckmark } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";

const TriggerButton = styled(DropdownMenu.Trigger, {
  backgroundColor: "#E6EBF9",
  padding: "10px 15px",
  borderRadius: "5px",
  fontSize: "16px",
  color: "#5a47d1",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 6px 0px rgba(183, 181, 188, 0.12)",
  minWidth: "150px",
});

const DropdownContent = styled(DropdownMenu.Content, {
  background: "#E6EBF9",
  borderRadius: "5px",
  padding: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  width: "160px",
});

const MenuItem = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
  cursor: "pointer",
});

const CheckboxLabel = styled("span", {
  color: "#5a47d1",
  fontSize: "16px",
  marginLeft: "10px",
});

const CheckboxStyled = styled(Checkbox, {
  width: "20px",
  height: "20px",
  border: "2px solid rgba(71, 69, 142, 0.5)",
  borderRadius: "4px",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&[data-state=checked]": {
    backgroundColor: "#5a47d1",
    color: "white",
  },
});

const FilterSelect = ({ options = [], selectedItems = [], onSelect }) => {
  // Handle selection toggle
  const handleSelect = (option) => {
    const updatedSelectedItems = selectedItems.includes(option)
      ? selectedItems.filter((item) => item !== option)
      : [...selectedItems, option];

    onSelect && onSelect(updatedSelectedItems); // Pass the updated items to the parent
  };

  const displayText = selectedItems.length
    ? `${selectedItems.length} items`
    : "All";

  return (
    <DropdownMenu.Root>
      <TriggerButton>
        <span>{displayText}</span>
        <IoChevronDown />
      </TriggerButton>
      <DropdownContent>
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleSelect(option)}>
            <CheckboxLabel>{option}</CheckboxLabel>
            <CheckboxStyled>
              {selectedItems.includes(option) && <IoMdCheckmark />}
            </CheckboxStyled>
          </MenuItem>
        ))}
      </DropdownContent>
    </DropdownMenu.Root>
  );
};

export default FilterSelect;
