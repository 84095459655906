export const userRoles = [
  { id: "project_manager", name: "Project Manager" },
  { id: "construction_manager", name: "Construction Manager" },
  { id: "site_engineer", name: "Site Engineer" },
  { id: "supervisor", name: "Supervisor" },
  { id: "safety_manager", name: "Safety Manager" },
  { id: "architect", name: "Architect" },
  { id: "structural_engineer", name: "Structural Engineer" },
  { id: "quality_control_inspector", name: "Quality Control Inspector" },
  { id: "subcontractor", name: "Subcontractor" },
];

export const cameraCommands = {
  MOVE_UP: "move_up",
  MOVE_DOWN: "move_down",
  MOVE_RIGHT: "move_right",
  MOVE_LEFT: "move_left",
  ZOOM_IN: "zoom_in",
  ZOOM_OUT: "zoom_out",
};
